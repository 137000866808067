import React from "react";

import { resizeImage } from "../../utils/canvas";

interface ImageInputProps {
  id: string;
  onChange: (value: File) => void;
  className?: string;
}

export const ImageInput = (props: ImageInputProps) => {
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const image = e.target.files[0];
    if (image.size > 200000) {
      try {
        const resizedImage = await resizeImage(image, 300, 300);
        props.onChange(resizedImage);
      } catch (error) {
        console.log(error);
      }
    } else {
      props.onChange(image);
    }
  };

  return (
    <input
      id={props.id}
      onChange={handleFileChange}
      className={props.className}
      type="file"
      accept="image/*"
    />
  );
};
