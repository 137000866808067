/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LastChangeEntityEnum } from '../models/LastChangeEntityEnum';
import type { SetLastChangeRequest } from '../models/SetLastChangeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LastChangesService {

    /**
     * @param entityName 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiChanges(
entityName?: LastChangeEntityEnum,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/changes',
            query: {
                'entityName': entityName,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiChanges(
requestBody?: SetLastChangeRequest,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/changes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
