import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { InternalStorageCategoriesService } from "../../services/internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageCommonService } from "../../services/internalStorage/services/InternalStorageCommonService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import { SyncService } from "../../services/sync/services/SyncService";
import { COMMON_STORE_KEYS } from "../../utils/constants";
import { getUniqueId } from "../../utils/dateTimeUtil";

export type CategoriesStateType = {
  categories: ListItemCategoryInternalModel[];
};

const initialState: CategoriesStateType = {
  categories: [],
};

export const fetchCategories = createAsyncThunk<ListItemCategoryInternalModel[] | null>(
  "categories/fetchCategories",
  async () => {
    try {
      return (await InternalStorageCategoriesService.getCategories()).filter(
        (category) => !category.deleted,
      );
    } catch (e) {
      return null;
    }
  },
);

export const addCategory = createAsyncThunk<
  ListItemCategoryInternalModel | null,
  { name: string; color: string }
>("categories/addCategory", async ({ name, color }) => {
  try {
    const newCategory = {
      id: null,
      localId: getUniqueId(),
      name,
      color,
      colorDark: color,
      order: 0,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      deleted: null,
    };
    await InternalStorageCategoriesService.addOrUpdateCategories([newCategory]);
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.CATEGORIES_LAST_CHANGE,
      new Date().toISOString(),
    );
    SyncService.enqueue();
    return newCategory;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const updateCategory = createAsyncThunk<
  ListItemCategoryInternalModel | null,
  ListItemCategoryInternalModel
>("categories/updateCategory", async (category: ListItemCategoryInternalModel) => {
  try {
    await InternalStorageCategoriesService.addOrUpdateCategories([category]);
    await Promise.all(
      (await InternalStorageListsService.getAllListItems())
        .filter((listItem) => listItem.localCategory?.localId === category.localId)
        .map(async (listItem) => {
          await InternalStorageListsService.updateListItem({
            localId: listItem.localId,
            localCategory: category,
          });
        }),
    );
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.CATEGORIES_LAST_CHANGE,
      new Date().toISOString(),
    );
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      new Date().toISOString(),
    );
    SyncService.enqueue();
    return category;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const deleteOneCategory = createAsyncThunk<
  number | null,
  ListItemCategoryInternalModel
>("categories/deleteOneCategory", async (category) => {
  try {
    await InternalStorageCommonService.addOrUpdateValue(
      COMMON_STORE_KEYS.CATEGORIES_LAST_CHANGE,
      new Date().toISOString(),
    );
    await InternalStorageCategoriesService.setCategoryDeleted(category.localId);
    SyncService.enqueue();
    return category.localId;
  } catch (e) {
    console.log(e);
    return null;
  }
});
export const deleteAllCategories = createAsyncThunk<void, void>(
  "categories/deleteAllCategories",
  async () => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.CATEGORIES_LAST_CHANGE,
        new Date().toISOString(),
      );
      await InternalStorageCategoriesService.deleteAllCategories();
    } catch (e) {
      console.log(e);
    }
  },
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      if (action.payload) {
        state.categories = action.payload;
      }
    });
    builder.addCase(addCategory.fulfilled, (state, action) => {
      if (action.payload) {
        state.categories.push(action.payload);
      }
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      const updatedCategory = action.payload;
      if (updatedCategory) {
        state.categories = state.categories.map((category) => {
          if (category.localId === updatedCategory.localId) {
            return { ...updatedCategory };
          }
          return category;
        });
      }
    });
    builder.addCase(deleteAllCategories.fulfilled, (state) => {
      state.categories = [];
    });
    builder.addCase(deleteOneCategory.fulfilled, (state, action) => {
      state.categories = state.categories.filter(
        (category) => category.localId != action.payload,
      );
    });
  },
});
