import { ChangesTracker } from "./ChangesTracker";

export abstract class Syncer {
  public changesTracker: ChangesTracker;
  protected afterSyncCallback: () => void;

  constructor(changesTracker: ChangesTracker, afterSyncCallback: () => void) {
    this.changesTracker = changesTracker;
    this.afterSyncCallback = afterSyncCallback;
  }

  abstract sync(signedId: boolean, wereChanges: boolean): Promise<void>;
}
