import { Box } from "@mui/material";
import "./CategoryBadge.scss";
import React from "react";

export interface CategoryBadgeProps {
  color: string;
  className?: string;
}

export const CategoryBadge = (props: CategoryBadgeProps) => {
  return (
    <Box
      className={`${props.className} category-badge`}
      sx={{ backgroundColor: props.color }}
    />
  );
};
