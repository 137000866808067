import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import "./CreateListDialog.scss";

export interface CreateListDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (name: string) => void;
}

export const CreateListDialog = (props: CreateListDialogProps) => {
  const [open, setOpen] = useState(props.open);
  const [newListName, setNewListName] = useState("");
  const handleClose = () => {
    props.onClose();
  };
  const handleAdd = () => {
    props.onAdd(newListName);
  };

  useEffect(() => {
    setOpen(props.open);
    setNewListName("");
  }, [props.open]);

  const changeEvent = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewListName(event.target.value.slice(0, 200));
  };

  const keydownEvent = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key == "Enter") {
      handleAdd();
    }
  };
  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>
        Добавить список
        <IconButton aria-label="close" onClick={handleClose} className="dialog-close">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          onChange={changeEvent}
          onKeyDown={keydownEvent}
          value={newListName}
          autoFocus
          margin="dense"
          id="name"
          label="Название"
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          onClick={handleAdd}
          className={"dialog-create-list" + (newListName.length > 0 ? " active" : "")}
          disabled={!newListName}
        >
          Создать
        </Button>
      </DialogActions>
    </Dialog>
  );
};
