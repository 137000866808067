import { Button } from "@mui/material";

import { Delete } from "../../assets/Icons/Delete";
import "./ResetButton.scss";

export interface ResetButtonProps {
  onClick: () => void;
  className?: string;
}

export const ResetButton = (props: ResetButtonProps) => {
  return (
    <Button
      className={`reset-button ${props?.className ?? ""}`}
      variant="text"
      color="secondary"
      startIcon={<Delete />}
      onClick={props.onClick}
    >
      Восстановление данных
    </Button>
  );
};
