import { COMMON_STORE_KEYS } from "../../../utils/constants";
import { getUniqueId } from "../../../utils/dateTimeUtil";
import { ListItemCategoryInternalModel } from "../../internalStorage/models/ListItemCategoryInternalModel";
import { InternalStorageCategoriesService } from "../../internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageCommonService } from "../../internalStorage/services/InternalStorageCommonService";
import { InternalStorageListsService } from "../../internalStorage/services/InternalStorageListsService";
import { InternalStoragePromptsService } from "../../internalStorage/services/InternalStoragePromptsService";
import { CategoriesService, CategoryDto, UserCategoriesService } from "../../openapi";
import { Syncer } from "../core/Syncer";

export class SyncCategoriesService extends Syncer {
  private async saveServerCategoriesToLocal(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStorageCategoriesService.addOrUpdateCategories(
      serverCategories
        .map((serverCategory: CategoryDto): ListItemCategoryInternalModel | undefined => {
          const isOnLocal: boolean = localCategories.some(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.id === serverCategory.id,
          );
          if (!isOnLocal) {
            return {
              id: serverCategory.id,
              localId: getUniqueId(),
              name: serverCategory.name,
              color: serverCategory.color,
              colorDark: serverCategory.colorDark,
              order: serverCategory.order,
              created: serverCategory.created,
              updated: serverCategory.updated,
              deleted: null,
            };
          }
        })
        .filter(
          (
            category: ListItemCategoryInternalModel | undefined,
          ): category is ListItemCategoryInternalModel => category !== undefined,
        ),
    );
  }

  private async deleteServerCategories(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverCategories.map(async (serverCategory: CategoryDto): Promise<void> => {
        const isLocalVersionDeleted = localCategories.some(
          (localCategory: ListItemCategoryInternalModel) =>
            localCategory.id === serverCategory.id && localCategory.deleted,
        );
        if (isLocalVersionDeleted) {
          await UserCategoriesService.deleteApiUserCategories(serverCategory.id);
          await InternalStorageCategoriesService.deleteCategoriesByLocalIds([
            serverCategory.id,
          ]);
        }
      }),
    );
  }

  private async postLocalCategoriesToServer(
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localCategories.map(
        async (localCategory: ListItemCategoryInternalModel): Promise<void> => {
          const wasOnServer = !!localCategory.id;
          const isDeleted = !!localCategory.deleted;
          if (!wasOnServer && !isDeleted) {
            const postResult: CategoryDto =
              await UserCategoriesService.postApiUserCategories({
                name: localCategory.name,
                order: localCategory.order,
                color: localCategory.color,
                colorDark: localCategory.color,
              });
            await InternalStorageCategoriesService.addOrUpdateCategories([
              { ...localCategory, id: postResult.id },
            ]);
          }
        },
      ),
    );
  }

  private async deleteLocalCategories(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStorageCategoriesService.deleteCategoriesByLocalIds(
      localCategories
        .map((localCategory: ListItemCategoryInternalModel): number | undefined => {
          const wasOnServer = !!localCategory.id;
          const isOnServer = serverCategories.some(
            (serverCategory: CategoryDto): boolean =>
              serverCategory.id === localCategory.id,
          );
          if (wasOnServer && !isOnServer) {
            return localCategory.localId;
          }
        })
        .filter(
          (localId: number | undefined): localId is number => localId !== undefined,
        ),
    );
  }

  private async updateLocalCategories(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStorageCategoriesService.addOrUpdateCategories(
      localCategories
        .map(
          (
            localCategory: ListItemCategoryInternalModel,
          ): ListItemCategoryInternalModel | undefined => {
            const serverCategory: CategoryDto | undefined = serverCategories.find(
              (serverCategory: CategoryDto): boolean =>
                serverCategory.id === localCategory.id,
            );
            if (!serverCategory?.updated || !localCategory.updated) {
              return;
            }
            const serverCategoryUpdated: number = new Date(
              serverCategory.updated,
            ).valueOf();
            const localCategoryUpdated: number = new Date(
              localCategory.updated,
            ).valueOf();
            const isServerCategoryNewer: boolean =
              serverCategoryUpdated - localCategoryUpdated > 0;

            if (isServerCategoryNewer && !localCategory.deleted) {
              return {
                id: serverCategory.id,
                localId: localCategory.localId,
                name: serverCategory.name,
                color: serverCategory.color,
                colorDark: serverCategory.colorDark,
                order: serverCategory.order,
                created: serverCategory.created,
                updated: serverCategory.updated,
                deleted: localCategory.deleted,
              };
            }
          },
        )
        .filter(
          (
            category: ListItemCategoryInternalModel | undefined,
          ): category is ListItemCategoryInternalModel => category !== undefined,
        ),
    );
  }

  private async updateServerCategories(
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverCategories.map(async (serverCategory: CategoryDto): Promise<void> => {
        const localCategory: ListItemCategoryInternalModel | undefined =
          localCategories.find(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.id === serverCategory.id,
          );
        if (!localCategory?.updated || !serverCategory.updated) {
          return;
        }
        const serverCategoryUpdated: number = new Date(serverCategory.updated).valueOf();
        const localCategoryUpdated: number = new Date(localCategory.updated).valueOf();
        const isLocalCategoryNewer: boolean =
          localCategoryUpdated - serverCategoryUpdated > 0;

        if (isLocalCategoryNewer && !localCategory.deleted) {
          if (serverCategory.isCustom) {
            await UserCategoriesService.putApiUserCategories(serverCategory.id, {
              name: localCategory.name,
              order: localCategory.order,
              color: localCategory.color,
              colorDark: localCategory.colorDark,
            });
          } else {
            const overridedCategory =
              await UserCategoriesService.putApiUserCategoriesOverride(
                serverCategory.id,
                {
                  name: localCategory.name,
                  order: localCategory.order,
                  color: localCategory.color,
                  colorDark: localCategory.colorDark,
                },
              );
            await InternalStorageCategoriesService.addOrUpdateCategories([
              {
                ...localCategory,
                id: overridedCategory.id,
              },
            ]);
            await Promise.all(
              (await InternalStorageListsService.getAllListItems())
                .filter(
                  (listItem) => listItem.localCategory?.localId === localCategory.localId,
                )
                .map(async (listItem) => {
                  await InternalStorageListsService.updateListItem({
                    localId: listItem.localId,
                    localCategory: {
                      ...localCategory,
                      id: overridedCategory.id,
                    },
                  });
                }),
            );
            await InternalStoragePromptsService.addOrUpdatePrompts(
              (await InternalStoragePromptsService.getPrompts())
                .filter((prompt) => prompt.localCategoryId === localCategory.localId)
                .map((prompt) => ({
                  ...prompt,
                  updated: new Date().toISOString(),
                })),
            );
            await InternalStorageCommonService.addOrUpdateValue(
              COMMON_STORE_KEYS.PROMPTS_LAST_CHANGE,
              new Date().toISOString(),
            );
          }
        }
      }),
    );
  }

  private async cleanUpSoftDeletedLocalCategories(
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localCategories.map(
        async (localCategory: ListItemCategoryInternalModel): Promise<void> => {
          if (localCategory.deleted) {
            await InternalStorageCategoriesService.deleteCategoriesByLocalIds([
              localCategory.localId,
            ]);
          }
        },
      ),
    );
  }

  public async sync(signedId: boolean, wereChanges: boolean): Promise<void> {
    if (!signedId) {
      const localCategories: ListItemCategoryInternalModel[] =
        await InternalStorageCategoriesService.getCategories();
      if (!localCategories.length) {
        console.log("Categories for anonymous fetching...");
        console.time("Categories for anonymous fetched");
        const serverCategories = await CategoriesService.getApiCategories();
        await this.saveServerCategoriesToLocal(serverCategories, []);
        console.timeEnd("Categories for anonymous fetched");
      }
    } else if (wereChanges) {
      console.log("Categories synchronization is running...");
      console.time("Categories synced");
      const beforeSyncTime = new Date().toISOString();
      const serverCategories = await UserCategoriesService.getApiUserCategories();
      const localCategories: ListItemCategoryInternalModel[] =
        await InternalStorageCategoriesService.getCategories();
      await this.saveServerCategoriesToLocal(serverCategories, localCategories);
      await this.postLocalCategoriesToServer(localCategories);
      await this.updateServerCategories(serverCategories, localCategories);
      await this.updateLocalCategories(serverCategories, localCategories);
      await this.deleteServerCategories(serverCategories, localCategories);
      await this.deleteLocalCategories(serverCategories, localCategories);
      await this.cleanUpSoftDeletedLocalCategories(localCategories);
      await this.changesTracker.setLastSync(beforeSyncTime);
      console.timeEnd("Categories synced");
    }
    this.afterSyncCallback();
  }
}
