import { Box, Divider, Typography } from "@mui/material";
import "./SettingOption.scss";
export type SettingProps = {
  text: string;
  icon: React.ReactNode;
  onClick?: () => void;
};

export const SettingOption = ({ icon, text, onClick }: SettingProps): JSX.Element => {
  return (
    <Box className="setting-option" onClick={onClick}>
      <Box className="setting-icon">{icon}</Box>
      <Typography className="setting-text">{text}</Typography>
    </Box>
  );
};
