import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
  AutocompleteRenderInputParams,
  ListItem,
  Typography,
} from "@mui/material";
import "./ListItemAutocomplete.scss";
import { TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import normalizeText from "../../utils/normalizeText";

export type ListItemPromptOption = {
  name: string;
  customInputValue?: string;
};

export interface ListItemAutocompleteProps {
  prompts?: ListItemPromptInternalModel[];
  onChange?: (listItemName: string) => void;
}

const filter = createFilterOptions<ListItemPromptOption>();
const LIMIT_PROMPT_OPTIONS = 4;

export const ListItemAutocomplete = (props: ListItemAutocompleteProps) => {
  const [newListItem, setNewListItem] = useState<ListItemPromptOption | null>(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (newListItem && props.onChange) {
      props.onChange(newListItem.name);
      setNewListItem(null);
    }
  }, [newListItem]);

  const listItemAutocompletePrompts: ListItemPromptOption[] = useMemo(() => {
    if (props.prompts) {
      const uniquePromptNames = new Set<string>();
      return props.prompts
        .filter((p) => {
          if (!uniquePromptNames.has(p.name)) {
            uniquePromptNames.add(p.name);
            return true;
          }
          return false;
        })
        .sort((a, b) => a.order - b.order)
        .map((p) => ({
          name: p.name,
        }));
    } else {
      return [];
    }
  }, [props.prompts]);

  const handleChangeAutocomplete = (
    _: React.SyntheticEvent,
    newValue: NonNullable<string | ListItemPromptOption> | null,
  ) => {
    if (typeof newValue === "string") {
      setNewListItem({
        name: newValue,
      });
    } else if (newValue && newValue.customInputValue) {
      setNewListItem({
        name: newValue.customInputValue,
      });
    } else {
      setNewListItem(newValue);
    }
  };

  const handleFilterAutocompleteOptions = (
    options: ListItemPromptOption[],
    params: FilterOptionsState<ListItemPromptOption>,
  ) => {
    const inputValue = normalizeText(params.inputValue);
    const filtered = filter(options, params)
      .sort(
        (a, b) =>
          a.name.indexOf(inputValue) - b.name.indexOf(inputValue) ||
          a.name.length - b.name.length,
      )
      .slice(0, LIMIT_PROMPT_OPTIONS);

    const isExisting = options.some((option) => inputValue === option.name);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        customInputValue: inputValue,
        name: `Добавить "${inputValue}"`,
      });
    }
    return filtered;
  };

  const handleGetOptionLabel = (option: string | ListItemPromptOption) => {
    if (typeof option === "string") {
      return option;
    }
    if (option.customInputValue) {
      return option.customInputValue;
    }
    return option.name;
  };

  const handleRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ListItemPromptOption,
  ) => {
    return (
      <ListItem key={option.name} {...props}>
        <Typography className="list-item-autocomplete-option-name">
          {option.name}
        </Typography>
      </ListItem>
    );
  };

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      autoFocus
      placeholder="Название позиции"
      variant="standard"
      InputProps={{
        ...params.InputProps,
        endAdornment: null,
      }}
    />
  );

  return (
    <Autocomplete
      value={newListItem}
      open={inputValue.length > 1}
      options={listItemAutocompletePrompts}
      className="list-item-autocomplete"
      autoFocus
      clearOnBlur
      handleHomeEndKeys
      id="create-list-item-autocomplete"
      freeSolo
      onChange={handleChangeAutocomplete}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue.slice(0, 200))}
      inputValue={inputValue}
      filterOptions={handleFilterAutocompleteOptions}
      getOptionLabel={handleGetOptionLabel}
      renderOption={handleRenderOption}
      renderInput={handleRenderInput}
    />
  );
};
