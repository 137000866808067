export const ArrowBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M17.733 23.0666L11.5997 16.9333C11.4664 16.8 11.3721 16.6555 11.317 16.5C11.261 16.3444 11.233 16.1778 11.233 16C11.233 15.8222 11.261 15.6555 11.317 15.5C11.3721 15.3444 11.4664 15.2 11.5997 15.0666L17.733 8.93332C17.9775 8.68887 18.2886 8.56665 18.6664 8.56665C19.0441 8.56665 19.3553 8.68887 19.5997 8.93332C19.8441 9.17776 19.9664 9.48887 19.9664 9.86665C19.9664 10.2444 19.8441 10.5555 19.5997 10.8L14.3997 16L19.5997 21.2C19.8441 21.4444 19.9664 21.7555 19.9664 22.1333C19.9664 22.5111 19.8441 22.8222 19.5997 23.0666C19.3553 23.3111 19.0441 23.4333 18.6664 23.4333C18.2886 23.4333 17.9775 23.3111 17.733 23.0666Z"
        fill="#1B2227"
      />
    </svg>
  );
};
