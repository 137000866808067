import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

interface YandexMetrikaProps {
  code: number;
}

export const YandexMetrika = (props: YandexMetrikaProps) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    if (typeof window !== "undefined" && window.ym) {
      window.ym(props.code, "hit", url);
    }
  }, [pathname, searchParams, props.code]);

  return null;
};
