import Alert from "@mui/material/Alert";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import * as React from "react";
import { ReactNode } from "react";
import "./AlertSnackbar.scss";

interface AlertSnackbarProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const AlertSnackbar = (props: AlertSnackbarProps) => {
  const handleClose = (
    _?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    props.onClose();
  };

  return (
    <Snackbar
      className="alert-snackbar"
      open={props.open}
      onClose={handleClose}
      autoHideDuration={5000}
    >
      <Alert onClose={handleClose} severity="error">
        {props.children}
      </Alert>
    </Snackbar>
  );
};
