import { Button } from "@mui/material";
import "./BaseButton.scss";

export interface BaseButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}
export const BaseButton = (props: BaseButtonProps) => {
  return (
    <Button
      variant="contained"
      className={"base-button" + " " + (props.className && props.className)}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};
