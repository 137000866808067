import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";

import Bucket from "../../assets/img/bucket.svg";
import { LoginInput } from "../LoginInput/LoginInput";
import "./FutureSettings.scss";

interface FutureSettings {
  onClose: () => void;
  onContinue: () => void;
  text: string;
}
export const FutureSettings = (props: FutureSettings) => {
  return (
    <Stack className="future-settings-container">
      <Typography className="future-settings-title">
        Скоро будет
        <IconButton className="future-settings-close" onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </Typography>
      <img src={Bucket} alt="Bucket" />
      <Typography className="future-settings-text">{props.text}</Typography>
    </Stack>
  );
};
