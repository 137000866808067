import { Box, SwipeableDrawer } from "@mui/material";
import { ReactNode } from "react";
import "./CurtainPopup.scss";

interface CurtainPopupProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  onOpen?: () => void;
}

export const CurtainPopup = (props: CurtainPopupProps) => {
  return (
    <SwipeableDrawer
      className="curtain-popup"
      anchor="bottom"
      open={props.open}
      onClose={props.onClose}
      onOpen={props.onOpen || (() => null)}
      disableSwipeToOpen={true}
    >
      <Box className="curtain-popup-toggle" />
      {props.children}
    </SwipeableDrawer>
  );
};
