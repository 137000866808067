export const Delete = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.17909 5V4.9875H4.16659C3.93362 4.9875 3.73894 4.90884 3.58167 4.75158C3.42441 4.59432 3.34575 4.39963 3.34575 4.16667C3.34575 3.9337 3.42441 3.73902 3.58167 3.58176C3.73894 3.42449 3.93362 3.34583 4.16659 3.34583H7.49992H7.51242V3.33333C7.51242 3.10037 7.59108 2.90568 7.74834 2.74842C7.9056 2.59116 8.10029 2.5125 8.33325 2.5125H11.6666C11.8995 2.5125 12.0942 2.59116 12.2515 2.74842C12.4088 2.90568 12.4874 3.10037 12.4874 3.33333V3.34583H12.4999H15.8333C16.0662 3.34583 16.2609 3.42449 16.4182 3.58176C16.5754 3.73902 16.6541 3.9337 16.6541 4.16667C16.6541 4.39963 16.5754 4.59432 16.4182 4.75158C16.2609 4.90884 16.0662 4.9875 15.8333 4.9875H15.8208V5V15.8333C15.8208 16.2883 15.6589 16.6775 15.3348 17.0016C15.0108 17.3256 14.6216 17.4875 14.1666 17.4875H5.83325C5.37826 17.4875 4.98907 17.3256 4.66501 17.0016C4.34094 16.6775 4.17909 16.2883 4.17909 15.8333V5ZM14.1791 5V4.9875H14.1666H5.83325H5.82075V5V15.8333V15.8458H5.83325H14.1666H14.1791V15.8333V5ZM8.91816 13.9182C8.7609 14.0755 8.56621 14.1542 8.33325 14.1542C8.10029 14.1542 7.9056 14.0755 7.74834 13.9182C7.59108 13.761 7.51242 13.5663 7.51242 13.3333V7.5C7.51242 7.26704 7.59108 7.07235 7.74834 6.91509C7.9056 6.75783 8.10029 6.67917 8.33325 6.67917C8.56621 6.67917 8.7609 6.75783 8.91816 6.91509C9.07543 7.07235 9.15409 7.26704 9.15409 7.5V13.3333C9.15409 13.5663 9.07543 13.761 8.91816 13.9182ZM12.2515 13.9182C12.0942 14.0755 11.8995 14.1542 11.6666 14.1542C11.4336 14.1542 11.2389 14.0755 11.0817 13.9182C10.9244 13.761 10.8458 13.5663 10.8458 13.3333V7.5C10.8458 7.26704 10.9244 7.07235 11.0817 6.91509C11.2389 6.75783 11.4336 6.67917 11.6666 6.67917C11.8995 6.67917 12.0942 6.75783 12.2515 6.91509C12.4088 7.07235 12.4874 7.26704 12.4874 7.5V13.3333C12.4874 13.5663 12.4088 13.761 12.2515 13.9182Z"
        fill="#8399A8"
        stroke="#8399A8"
        strokeWidth="0.025"
      />
    </svg>
  );
};
