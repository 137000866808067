import { COMMON_STORE_KEYS } from "../../../utils/constants";
import { getDateValue } from "../../../utils/dateTimeUtil";
import { InternalStorageCommonService } from "../../internalStorage/services/InternalStorageCommonService";
import { LastChangeEntityEnum, LastChangesService } from "../../openapi";

export class ChangesTracker {
  private readonly entity: number;
  private readonly lastChangeKey: COMMON_STORE_KEYS;
  private readonly lastSyncKey: COMMON_STORE_KEYS;

  constructor(entity: LastChangeEntityEnum) {
    this.entity = entity;
    this.lastChangeKey = {
      0: COMMON_STORE_KEYS.LISTS_LAST_CHANGE,
      1: COMMON_STORE_KEYS.PROMPTS_LAST_CHANGE,
      2: COMMON_STORE_KEYS.CATEGORIES_LAST_CHANGE,
    }[entity];
    this.lastSyncKey = {
      0: COMMON_STORE_KEYS.LISTS_LAST_SYNC,
      1: COMMON_STORE_KEYS.PROMPTS_LAST_SYNC,
      2: COMMON_STORE_KEYS.CATEGORIES_LAST_SYNC,
    }[entity];
  }

  public async getFrontendLastChange(): Promise<string> {
    let res = (await InternalStorageCommonService.getValue(this.lastChangeKey)) as
      | string
      | undefined;
    if (!res) {
      res = new Date().toISOString();
      await InternalStorageCommonService.addOrUpdateValue(this.lastChangeKey, res);
    }
    return res;
  }

  public async getFrontendLastSync(): Promise<string> {
    let res = (await InternalStorageCommonService.getValue(this.lastSyncKey)) as
      | string
      | undefined;
    if (!res) {
      res = new Date(0).toISOString();
      await InternalStorageCommonService.addOrUpdateValue(this.lastSyncKey, res);
    }
    return res;
  }

  public async isFrontendChanged(): Promise<boolean> {
    const frontendLastChange = await this.getFrontendLastChange();
    const lastSync = await this.getFrontendLastSync();
    return getDateValue(frontendLastChange) > getDateValue(lastSync);
  }

  public async isBackendChanged(): Promise<boolean> {
    const backendLastChange = await LastChangesService.getApiChanges(this.entity);
    const lastSync = await this.getFrontendLastSync();
    return getDateValue(`${backendLastChange}Z`) > getDateValue(lastSync);
  }

  public async setLastSync(beforeSyncTime: string) {
    const frontendLastChange = await this.getFrontendLastChange();
    const wereChangesDuringSync =
      getDateValue(frontendLastChange) > getDateValue(beforeSyncTime);
    const timeToSet = wereChangesDuringSync ? beforeSyncTime : new Date().toISOString();
    await InternalStorageCommonService.addOrUpdateValue(this.lastSyncKey, timeToSet);
    LastChangesService.postApiChanges({ entity: this.entity, dateTime: timeToSet });
  }

  public async check(): Promise<boolean> {
    return (await this.isFrontendChanged()) || (await this.isBackendChanged());
  }
}
