import { createSlice } from "@reduxjs/toolkit";

export type LoginType = {
  email: string;
  password: string;
};

const initialState: LoginType = {
  email: "",
  password: "",
};
export const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    setPassword(state, action: { payload: string }) {
      state.password = action.payload;
    },
    setEmail(state, action: { payload: string }) {
      state.email = action.payload;
    },
  },
});

export const { setEmail, setPassword } = loginSlice.actions;
