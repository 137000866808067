export const resizeImage = (
  file: File,
  maxWidth: number,
  maxHeight: number,
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          return reject(new Error("Canvas context could not be created"));
        }

        let { width, height } = img;

        if (width > maxWidth || height > maxHeight) {
          if (width / height > maxWidth / maxHeight) {
            height = (height * maxWidth) / width;
            width = maxWidth;
          } else {
            width = (width * maxHeight) / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const originalFilename = file.name;
              const extension =
                originalFilename.substring(originalFilename.lastIndexOf(".") + 1) ||
                "jpg";
              const resizedFile = new File([blob], `resized-image.${extension}`, {
                type: `image/${extension}`,
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Canvas could not produce a blob"));
            }
          },
          "image/jpeg",
          0.9,
        );
      };

      img.onerror = (error) => reject(error);
      if (event.target?.result) {
        img.src = event.target.result as string;
      }
    };

    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};
