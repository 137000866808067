import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "./LoginPage.scss";
import { Cancel } from "../../../assets/Icons/Cancel";
import { EyeClosed } from "../../../assets/Icons/EyeClosed";
import { EyeOpened } from "../../../assets/Icons/EyeOpened";
import { Alert } from "../../../components/Alert/Alert";
import { CurtainPopup } from "../../../components/CurtainPopup/CurtainPopup";
import { EmailDialog } from "../../../components/Dialogs/EmailDialog/EmailDialog";
import { LoginCheck } from "../../../components/LoginCheck/LoginCheck";
import { LoginInput } from "../../../components/LoginInput/LoginInput";
import { LoginQuestion } from "../../../components/LoginQuestion/LoginQuestion";
import { LoginTitle } from "../../../components/LoginTitle/LoginTitle";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import { loginByEmail } from "../../../store/auth/authSlice";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setEmail, setPassword } from "../../../store/login/loginSlice";
import { selectLoginState } from "../../../store/login/selector";
import { emailRegex, Paths } from "../../../utils/constants";

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const { email, password } = useAppSelector(selectLoginState);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { signedIn } = useAppSelector(selectAuthState);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isCheckOpen, setIsCheckOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);

  useEffect(() => {
    if (signedIn) {
      navigate("/");
    }
  }, [signedIn, navigate]);

  useEffect(() => {
    setIsLoginError(false);
  }, [email, password]);

  const handleLogin = async () => {
    setShowError(true);
    if (emailRegex.test(email) && password) {
      setIsLoginLoading(true);
      const loginAction = await dispatch(loginByEmail({ email: email, password }));
      setIsLoginLoading(false);
      if (!loginAction.payload) {
        setIsLoginError(true);
      }
    }
  };

  if (signedIn) {
    return null;
  }

  return (
    <Stack className="login-container">
      <LoginTitle text="Добро пожаловать!  👋" backPath={Paths.LOGIN_HOME} />
      <Box className="login-inputs">
        <LoginInput
          value={email}
          onChange={(val) => dispatch(setEmail(val.slice(0, 200)))}
          icon={email && <Cancel />}
          name="Ваш адрес электронной почты"
          onIconClick={() => dispatch(setEmail(""))}
          type="email"
          errorText="Email должен быть формата test@test.ru"
          isError={showError && !emailRegex.test(email)}
        />
        <LoginInput
          value={password}
          onChange={(val) => dispatch(setPassword(val.slice(0, 200)))}
          icon={isPasswordVisible ? <EyeOpened /> : <EyeClosed />}
          name="Ваш пароль"
          onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
          type={isPasswordVisible ? "text" : "password"}
          errorText="Обязательно для заполнения"
          isError={showError && password.length == 0}
        />
        {isLoginError && (
          <Alert type="danger">
            Пользователь с таким логином и паролем не зарегистрирован.{" "}
            <NavLink className="signup-alert-link" to={Paths.SIGN_UP}>
              Зарегистрироваться?
            </NavLink>
          </Alert>
        )}
        {/*<Box className="login-warning" onClick={() => setIsCheckOpen(true)}>*/}
        {/*  <Typography>Забыли пароль?</Typography>*/}
        {/*</Box>*/}
      </Box>
      <Box className="login-bottom">
        <Button className="login-continue" variant="contained" onClick={handleLogin}>
          Продолжить
        </Button>
        <LoginQuestion
          question="Нет аккаунта?"
          linkText="Регистрация"
          linkAddress={Paths.SIGN_UP}
        />
      </Box>
      <CurtainPopup open={isCheckOpen} onClose={() => setIsCheckOpen(false)}>
        <LoginCheck
          onClose={() => setIsCheckOpen(false)}
          onContinue={() => setIsDialogOpen(true)}
        />
      </CurtainPopup>
      <EmailDialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen} />
      {isLoginLoading && <PageLoading />}
    </Stack>
  );
};
