import LinkIcon from "@mui/icons-material/Link";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import "./ShareList.scss";
import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import { URL_PARAMS } from "../../utils/constants";
import { CopyField } from "../CopyField/CopyField";

export interface ShareListProps {
  onFetchShareKey: (listId: number | null) => void;
  shareListKey: string | null;
  selectedList: ListInternalModel;
  close: () => void;
}

export const ShareList = (props: ShareListProps) => {
  const [shareLink, setShareLink] = useState("");

  useEffect(() => {
    props.onFetchShareKey(props.selectedList.id);
  }, [props.selectedList.id]);

  useEffect(() => {
    if (props.shareListKey !== null) {
      setShareLink(
        `${window.location.href}?${URL_PARAMS.SHARE_LIST_KEY}=${props.shareListKey}`,
      );
    }
  }, [props.shareListKey]);

  const handleShareLinkClick = async () => {
    try {
      await navigator.share({
        url: shareLink,
      });
    } catch (err) {
      console.log(`Couldn't share ${err}`);
    }
  };

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/?text=${shareLink}`);
  };

  const handleTelegramClick = () => {
    window.open(`https://t.me/share/url?url=${shareLink}`);
  };

  if (!shareLink) {
    return (
      <Box className="share-list-loader-container">
        <CircularProgress className="share-list-loader" />
      </Box>
    );
  }

  return (
    <Box className="share-list">
      <Box className="share-list-copy">
        <Typography>Скопировать ссылку</Typography>
        <CopyField value={shareLink} />
      </Box>
      <List className="share-list-tabs">
        <ListItem className="share-list-tabs-item" onClick={handleShareLinkClick}>
          <IconButton size="large">
            <LinkIcon fontSize="large" />
          </IconButton>
          <Typography className="share-list-tabs-item-text">Ссылка</Typography>
        </ListItem>
        <ListItem className="share-list-tabs-item" onClick={handleWhatsAppClick}>
          <IconButton size="large">
            <WhatsAppIcon fontSize="large" />
          </IconButton>
          <Typography className="share-list-tabs-item-text">WhatsApp</Typography>
        </ListItem>
        <ListItem className="share-list-tabs-item" onClick={handleTelegramClick}>
          <IconButton size="large">
            <TelegramIcon fontSize="large" />
          </IconButton>
          <Typography className="share-list-tabs-item-text">Телеграм</Typography>
        </ListItem>
      </List>
    </Box>
  );
};
