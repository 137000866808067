import MenuIcon from "@mui/icons-material/Menu";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { Chip, IconButton, Box } from "@mui/material";
import React, { ReactNode } from "react";

import "./AppTopBar.scss";

export interface AppTopBarProps {
  isOnline: boolean;
  onMenuClick: () => void;
  children: ReactNode;
}

export const AppTopBar = (props: AppTopBarProps) => {
  return (
    <Box className="top-bar">
      <Box className="top-bar-tools">
        <IconButton onClick={() => props.onMenuClick()} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        {props.children}
      </Box>
      {!props.isOnline && (
        <Chip
          icon={<WifiOffIcon color="inherit" />}
          label="Вы не в сети"
          color="warning"
          size="small"
        />
      )}
    </Box>
  );
};
