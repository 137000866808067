import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FutureFunctionsService } from "../../services/openapi";

export const sendFutureFeatures = createAsyncThunk<Promise<boolean>, string[]>(
  "futureFeatures/sendFutureFeatures",
  async (features) => {
    try {
      await FutureFunctionsService.postApiFutureFunctions({ functions: features });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
);

export const futureFeaturesSlice = createSlice({
  name: "futureFeatures",
  initialState: {},
  reducers: {},
});
