import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  Chip,
  MenuItem,
  Select,
  Box,
  Typography,
  Avatar,
  useTheme,
  SelectChangeEvent,
  MenuProps,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useRef, useState } from "react";

import "./ListsSelect.scss";
import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import { User } from "../../services/openapi";

export interface ListsSelectProps {
  lists: ListInternalModel[];
  selectedList: ListInternalModel;
  user: User | null;
  onSelectList: (event: SelectChangeEvent) => void;
}

export const ListsSelect = (props: ListsSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const touchStartRef = useRef<number | null>(null);
  const touchEndRef = useRef<number | null>(null);
  const theme = useTheme();

  const handleTouchStart = (e: React.TouchEvent) => {
    const targetClassList = (e?.target as Element)?.classList;
    if (targetClassList) {
      const isMenu =
        targetClassList.contains("MuiButtonBase-root") ||
        targetClassList.contains("MuiSvgIcon-root");
      if (!isMenu) {
        touchStartRef.current = e.targetTouches[0].clientY;
      }
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndRef.current = e.changedTouches[0].clientY;
    if (touchStartRef.current && touchEndRef.current) {
      if (touchStartRef.current - touchEndRef.current > 50) {
        setIsOpen(false);
      }
    }
    touchStartRef.current = null;
    touchEndRef.current = null;
  };

  const SelectMenuProps: Partial<MenuProps> = {
    sx: {
      ...theme.components?.MuiSelect?.defaultProps?.MenuProps?.sx,
      "& .MuiPaper-root": {
        margin: "3px auto 0",
        maxHeight: "300px",
        borderRadius: "0 0 6px 6px",
        boxShadow: "none",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "initial",
          left: "0 !important",
          right: "0 !important",
        },
      },
      "& .MuiBackdrop-root": {
        background: `linear-gradient(
          to bottom,
          transparent,
          transparent 68px,
          ${alpha(theme.palette.grey["800"], 0.5)} 68px,
          ${alpha(theme.palette.grey["800"], 0.5)} 100%
        )`,
      },
    },
    slotProps: {
      root: {
        onTouchStart: handleTouchStart,
        onTouchEnd: handleTouchEnd,
      },
    },
  };

  return (
    <Select
      value={props.selectedList.localId?.toString()}
      onChange={props.onSelectList}
      renderValue={() => props.selectedList?.name}
      MenuProps={SelectMenuProps}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      {props.lists?.map((list) => (
        <MenuItem key={list.localId} value={list.localId}>
          <Box className="lists-toolbar-select-item-content">
            <Typography>{list.name}</Typography>
            {props?.user?.id && list?.owner?.id && props.user.id !== list.owner.id && (
              <Chip
                avatar={<Avatar alt="List owner avatar" src="" />}
                label={list.owner.name}
              />
            )}
          </Box>
          {props.selectedList?.localId === list?.localId && (
            <CheckOutlinedIcon
              className="lists-toolbar-select-item-check"
              fontSize="small"
            />
          )}
        </MenuItem>
      ))}
    </Select>
  );
};
