import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { BeforeInstallPromptEvent } from "pwa-install-handler";
import React, { useState, useEffect } from "react";

import bucketImage from "../../../assets/img/bucket.svg";
import "./InstallPwaDialog.scss";

interface InstallPwaDialogProps {
  interval: number;
  delay: number;
  onShouldShow: (interval: number) => Promise<boolean>;
  onAfterShow: () => void;
}

export const InstallPwaDialog = (props: InstallPwaDialogProps) => {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(
    null,
  );
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setTimeout(async () => {
        const shouldShow = await props.onShouldShow(props.interval);
        if (shouldShow) {
          setDeferredPrompt(event);
          setIsDialogOpened(true);
          props.onAfterShow();
        }
      }, props.delay);
    };

    const handleAppInstalled = () => {
      setDeferredPrompt(null);
      setIsDialogOpened(false);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener,
    );
    window.addEventListener("appinstalled", handleAppInstalled as EventListener);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener,
      );
      window.removeEventListener("appinstalled", handleAppInstalled as EventListener);
    };
  }, [props.interval, props.delay, props.onShouldShow]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      await deferredPrompt.prompt();
      setIsDialogOpened(false);
      setDeferredPrompt(null);
    }
  };

  return (
    <Dialog open={isDialogOpened} onClose={() => setIsDialogOpened(false)}>
      <DialogTitle className="install-pwa-title">
        Нравится приложение? Сохраните.
      </DialogTitle>
      <DialogContent className="install-pwa-info">
        <Box
          component="img"
          className="install-pwa-info-logo"
          src={bucketImage}
          alt="лого"
        />
        <Typography className="install-pwa-info-title">Listy - список покупок</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDialogOpened(false)}>Отмена</Button>
        <Button onClick={handleInstallClick}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};
