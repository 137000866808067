import "./VoiceAnimation.scss";

interface VoiceAnimationProps {
  className: string;
}

export const VoiceAnimation = (props: VoiceAnimationProps) => {
  return (
    <div className={`${props.className} voice-animation`}>
      <div className="voice-animation-bar"></div>
      <div className="voice-animation-bar"></div>
      <div className="voice-animation-bar"></div>
      <div className="voice-animation-bar"></div>
    </div>
  );
};
