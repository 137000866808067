import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import {
  IconButton,
  Box,
  Typography,
  Menu,
  Button,
  ListItem,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";

import "./ListsToolbar.scss";
import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import { User } from "../../services/openapi";
import { ListsSelect } from "../ListsSelect/ListsSelect";

export interface ListsToolbarProps {
  lists: ListInternalModel[];
  selectedList: ListInternalModel | null;
  user: User | null;
  onAddListClick: () => void;
  onDeleteListClick: () => void;
  onShareListClick: () => void;
  onSelectList: (event: SelectChangeEvent) => void;
}

export const ListsToolbar = (props: ListsToolbarProps) => {
  const [anchorListMenu, setAnchorListMenu] = useState<null | HTMLElement>(null);
  const isListMenuOpened = Boolean(anchorListMenu);

  const handleOpenListMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorListMenu(event.currentTarget);
  };

  const handleListMenuDeleteClick = () => {
    setAnchorListMenu(null);
    props.onDeleteListClick();
  };

  const handleListMenuAddClick = () => {
    setAnchorListMenu(null);
    props.onAddListClick();
  };

  return (
    <Box className="lists-toolbar">
      <Box className="lists-toolbar-select">
        {props.selectedList?.name?.length && (
          <ListsSelect
            lists={props.lists}
            selectedList={props.selectedList}
            onSelectList={props.onSelectList}
            user={props.user}
          />
        )}
      </Box>
      <Box>
        {props.selectedList && (
          <IconButton onClick={props.onShareListClick} color="inherit" aria-label="add">
            <PersonAddOutlinedIcon />
          </IconButton>
        )}
        {props.selectedList && (
          <IconButton
            onClick={handleOpenListMenu}
            color="inherit"
            aria-label="list menu button"
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>
      {props.selectedList && (
        <Menu
          aria-labelledby="list options"
          anchorEl={anchorListMenu}
          open={isListMenuOpened}
          onClose={() => setAnchorListMenu(null)}
        >
          <ListItem>
            <Button
              className="lists-toolbar-menu-list-button"
              variant="text"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={handleListMenuAddClick}
            >
              <Typography>Создать новый список</Typography>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              className="lists-toolbar-menu-list-button"
              variant="text"
              color="error"
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={handleListMenuDeleteClick}
            >
              <Typography>Удалить список</Typography>
            </Button>
          </ListItem>
        </Menu>
      )}
    </Box>
  );
};
