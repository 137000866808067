import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Button, Box, Typography, IconButton } from "@mui/material";
import "./ListItemButton.scss";
import React from "react";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import { AddIcon } from "../AddIcon/AddIcon";
import { CategoryBadge } from "../CategoryBadge/CategoryBadge";
import { ListItemAutocomplete } from "../ListItemAutocomplete/ListItemAutocomplete";
export interface ListItemButtonProps {
  name?: string;
  localId?: number;
  category?: ListItemCategoryInternalModel | null;
  prompts?: ListItemPromptInternalModel[];
  isChecked: boolean;
  onClick?: (listItemId: number) => void;
  onEditClick?: (listItemId: number) => void;
  onSaveNewListItem?: (listItemName: string) => void;
  onCancelListItemCreation?: () => void;
}

export const ListItemButton = (props: ListItemButtonProps) => {
  const handleClick = () => {
    if (props.onClick && props.localId) {
      props.onClick(props.localId);
    }
  };

  const handleEditClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (props.onEditClick && props.localId) {
      props.onEditClick(props.localId);
    }
  };

  return (
    <Box className="list-item-button">
      <Button color="secondary" className="list-item-button-body" onClick={handleClick}>
        {props.isChecked ? (
          <CheckCircleRoundedIcon
            color="primary"
            className="list-item-button-body-checked-icon"
          />
        ) : (
          <Box className="list-item-button-body-unchecked-icon" />
        )}
        {props.onSaveNewListItem ? (
          <ListItemAutocomplete
            prompts={props.prompts}
            onChange={props.onSaveNewListItem}
          />
        ) : (
          <Typography className="list-item-button-body-name">{props.name}</Typography>
        )}
      </Button>
      <Box className="list-item-button-controls">
        {props.onEditClick && (
          <IconButton
            className="list-item-button-controls-button"
            onClick={handleEditClick}
          >
            {props.category ? (
              <CategoryBadge color={props.category.colorDark} />
            ) : (
              <AddIcon />
            )}
          </IconButton>
        )}
        {props.onCancelListItemCreation && (
          <IconButton
            className="list-item-button-controls-button"
            onClick={props.onCancelListItemCreation}
          >
            <CancelOutlinedIcon color="error" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
