import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { Paths } from "../../../utils/constants";
import { ShareList } from "../../ShareList/ShareList";

import "./ShareListDialog.scss";

export interface ShareListDialogProps {
  open: boolean;
  onClose: () => void;
  signedIn: boolean;
  onFetchShareKey: (listId: number | null) => void;
  shareListKey: string | null;
  selectedList: ListInternalModel;
}

export const ShareListDialog = (props: ShareListDialogProps) => {
  const navigate = useNavigate();

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} className="share-list-dialog">
      <DialogTitle className="share-list-dialog-title">
        {props.signedIn ? "Поделиться списком" : "Вы не авторизованы"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="share-list-dialog-close"
      >
        <Close />
      </IconButton>
      <DialogContent className="share-list-dialog-content">
        {props.signedIn ? (
          <ShareList
            onFetchShareKey={props.onFetchShareKey}
            shareListKey={props.shareListKey}
            selectedList={props.selectedList}
            close={props.onClose}
          />
        ) : (
          <Typography>
            Для того чтобы поделиться списком, необходимо войти в приложение
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="share-list-dialog-actions">
        {props.signedIn ? (
          <Button className="share-list-dialog-close-button" onClick={props.onClose}>
            Закрыть
          </Button>
        ) : (
          <Button
            className="share-list-dialog-proceed-button"
            variant="contained"
            onClick={() => navigate(Paths.LOGIN_HOME)}
          >
            Продолжить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
