import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./AcceptConditionDialog.scss";
import { Link } from "react-router-dom";

export interface AcceptConditionsDialogProps {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
  termsUrl: string;
  privacyUrl: string;
}

export const AcceptConditionsDialog = (props: AcceptConditionsDialogProps) => {
  return (
    <Dialog open={props.open} onClose={() => props.onClose} className="accept-dialog">
      <DialogTitle className="accept-dialog-title">Принять наши условия</DialogTitle>
      <DialogContent className="accept-dialog-content">
        <Typography>
          Продолжая, вы соглашаетесь с{" "}
          <Link to={props.termsUrl} target="_blank" className="accept-dialog-link">
            Условиями использования Listy{" "}
          </Link>
          и принимаете{" "}
          <Link to={props.privacyUrl} target="_blank" className="accept-dialog-link">
            Политику конфиденциальности
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions className="accept-dialog-actions">
        <Button onClick={() => props.onClose()} className="accept-dialog-cancel">
          Отменить
        </Button>
        <Button
          onClick={() => props.onAccept()}
          variant="contained"
          className="accept-dialog-accept"
        >
          Продолжить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
