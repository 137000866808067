import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import "./Slider.scss";
import Bucket from "../../assets/img/bucket.svg";

const SwipeableTextMobileStepper = () => {
  return (
    <Box className="slider-container">
      <Swiper
        spaceBetween={30}
        pagination={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        autoplay={{
          delay: 50000000,
          disableOnInteraction: true,
        }}
      >
        <SwiperSlide>
          <Stack className="slider-slide" alignItems={"center"}>
            <img className="slider-image" src={Bucket} alt="bucket" />
            <Stack className="slider-info">
              <Typography className="slider-slide-title">
                Никогда не забывайте, что нужно купить
              </Typography>
              <Typography className="slider-slide-text">
                Мгновенно создавайте списки и всегда покупайте то, что вам действительно
                нужно
              </Typography>
            </Stack>
          </Stack>
        </SwiperSlide>
        <SwiperSlide>
          <Stack className="slider-slide" alignItems={"center"}>
            <img className="slider-image" src={Bucket} alt="bucket" />
            <Stack className="slider-info">
              <Typography className="slider-slide-title">
                Никогда не забывайте, что нужно купить
              </Typography>
              <Typography className="slider-slide-text">
                Мгновенно создавайте списки и всегда покупайте то, что вам действительно
                нужно
              </Typography>
            </Stack>
          </Stack>
        </SwiperSlide>
        <SwiperSlide>
          <Stack className="slider-slide" alignItems={"center"}>
            <img className="slider-image" src={Bucket} alt="bucket" />
            <Stack className="slider-info">
              <Typography className="slider-slide-title">
                Никогда не забывайте, что нужно купить
              </Typography>
              <Typography className="slider-slide-text">
                Мгновенно создавайте списки и всегда покупайте то, что вам действительно
                нужно
              </Typography>
            </Stack>
          </Stack>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default SwipeableTextMobileStepper;
