import { IDBPDatabase } from "idb";

import { COMMON_STORE_KEYS } from "../../../utils/constants";
import { Stores } from "../core/Stores";
import getDb from "../core/getDb";

export class InternalStorageCommonService {
  public static async addOrUpdateValue(
    key: COMMON_STORE_KEYS,
    value: unknown,
    dbName?: string,
  ): Promise<void> {
    try {
      const db: IDBPDatabase = await getDb(dbName);
      await db.put(Stores.COMMON, value, key);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async getValue(
    key: COMMON_STORE_KEYS,
    dbName?: string,
  ): Promise<unknown> {
    try {
      const db: IDBPDatabase = await getDb(dbName);
      return await db.get(Stores.COMMON, key);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
