import { Box, IconButton, Typography } from "@mui/material";

import "./WarningRegistrationAlert.scss";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import Close from "../../assets/Icons/close.svg";
import Info from "../../assets/Icons/warning.svg";
import { Paths } from "../../utils/constants";
export type WarningRegistrationAlert = {
  interval: number;
  delay: number;
  onShouldShow: (interval: number) => Promise<boolean>;
  onAfterShow: () => void;
};

export const WarningRegistrationAlert = (props: WarningRegistrationAlert) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setTimeout(async () => {
      const shouldShow = await props.onShouldShow(props.interval);
      setIsVisible(shouldShow);
    }, props.delay);
  }, [props.interval, props.delay, props.onShouldShow]);
  return (
    <Box className={"alert__container" + (isVisible ? " active" : "")}>
      <img src={Info} alt="" className={"alert__info active"} />
      <Box className="alert__content">
        <h4 className="alert__header-title">Требуется авторизация</h4>
        <Typography className="alert__description">
          Вы можете потерять свои данные, требуется авторизоваться в приложении
        </Typography>
        <NavLink to={Paths.SIGN_UP} className={"alert__link"}>
          Зарегистрировать аккаунт
        </NavLink>
      </Box>
      <IconButton
        className="alert__header-close"
        onClick={() => {
          props.onAfterShow();
          setIsVisible(false);
        }}
      >
        <img src={Close} alt="close" />
      </IconButton>
    </Box>
  );
};
