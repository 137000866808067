export const Info = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0001 14.1667C10.2362 14.1667 10.4342 14.0867 10.5942 13.9267C10.7537 13.7672 10.8334 13.5695 10.8334 13.3334V9.97919C10.8334 9.74308 10.7537 9.54863 10.5942 9.39585C10.4342 9.24308 10.2362 9.16669 10.0001 9.16669C9.76397 9.16669 9.56619 9.24641 9.40675 9.40585C9.24675 9.56585 9.16675 9.76391 9.16675 10V13.3542C9.16675 13.5903 9.24675 13.7847 9.40675 13.9375C9.56619 14.0903 9.76397 14.1667 10.0001 14.1667ZM10.0001 7.50002C10.2362 7.50002 10.4342 7.42002 10.5942 7.26002C10.7537 7.10058 10.8334 6.9028 10.8334 6.66669C10.8334 6.43058 10.7537 6.23252 10.5942 6.07252C10.4342 5.91308 10.2362 5.83335 10.0001 5.83335C9.76397 5.83335 9.56619 5.91308 9.40675 6.07252C9.24675 6.23252 9.16675 6.43058 9.16675 6.66669C9.16675 6.9028 9.24675 7.10058 9.40675 7.26002C9.56619 7.42002 9.76397 7.50002 10.0001 7.50002ZM10.0001 18.3334C8.8473 18.3334 7.76397 18.1145 6.75008 17.6767C5.73619 17.2395 4.85425 16.6459 4.10425 15.8959C3.35425 15.1459 2.76064 14.2639 2.32341 13.25C1.88564 12.2361 1.66675 11.1528 1.66675 10C1.66675 8.84724 1.88564 7.76391 2.32341 6.75002C2.76064 5.73613 3.35425 4.85419 4.10425 4.10419C4.85425 3.35419 5.73619 2.7603 6.75008 2.32252C7.76397 1.8853 8.8473 1.66669 10.0001 1.66669C11.1529 1.66669 12.2362 1.8853 13.2501 2.32252C14.264 2.7603 15.1459 3.35419 15.8959 4.10419C16.6459 4.85419 17.2395 5.73613 17.6767 6.75002C18.1145 7.76391 18.3334 8.84724 18.3334 10C18.3334 11.1528 18.1145 12.2361 17.6767 13.25C17.2395 14.2639 16.6459 15.1459 15.8959 15.8959C15.1459 16.6459 14.264 17.2395 13.2501 17.6767C12.2362 18.1145 11.1529 18.3334 10.0001 18.3334ZM10.0001 16.6667C11.8473 16.6667 13.4204 16.0175 14.7192 14.7192C16.0176 13.4203 16.6667 11.8472 16.6667 10C16.6667 8.1528 16.0176 6.57974 14.7192 5.28085C13.4204 3.98252 11.8473 3.33335 10.0001 3.33335C8.15286 3.33335 6.58008 3.98252 5.28175 5.28085C3.98286 6.57974 3.33341 8.1528 3.33341 10C3.33341 11.8472 3.98286 13.4203 5.28175 14.7192C6.58008 16.0175 8.15286 16.6667 10.0001 16.6667Z"
        fill="#158AD6"
      />
    </svg>
  );
};
