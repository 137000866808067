import { Avatar, Box, Typography } from "@mui/material";
import "./UserLogoName.scss";

export interface UserLogoNameProps {
  userName: string;
  userLogoUrl: string;
}

export const UserLogoName = (props: UserLogoNameProps) => {
  return (
    <Box className="user-logo">
      <Avatar className="user-logo-image" alt="User logo" src={props.userLogoUrl} />
      <Typography className="user-logo-name">{props.userName}</Typography>
    </Box>
  );
};
