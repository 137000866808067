import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import "./EmailDialog.scss";
interface EmailDialogProps {
  onClose: () => void;
  open: boolean;
}
export const EmailDialog = (props: EmailDialogProps) => {
  return (
    <Dialog open={props.open} onClose={() => props.onClose} className="email-dialog">
      <DialogTitle className="email-dialog-title">Проверьте почту</DialogTitle>
      <DialogContent className="email-dialog-content">
        <Typography>
          Мы отправили вам письмо со ссылкой для входа на ivan@mail.ru
        </Typography>
      </DialogContent>
      <DialogActions className="email-dialog-actions">
        <Button onClick={() => props.onClose()} className="email-dialog-cancel">
          Отправить ещё раз
        </Button>
        <Button
          className="emai-dialog-continue"
          onClick={() => {
            window.open(`https://mail.google.com/`);
          }}
        >
          Открыть сообщения
        </Button>
      </DialogActions>
    </Dialog>
  );
};
