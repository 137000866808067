import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { InternalStorageCommonService } from "../../services/internalStorage/services/InternalStorageCommonService";
import { ChangesTracker } from "../../services/sync/core/ChangesTracker";
import { SyncCategoriesService } from "../../services/sync/services/SyncCategoriesService";
import { SyncListsService } from "../../services/sync/services/SyncListsService";
import { SyncPromptsService } from "../../services/sync/services/SyncPromptsService";
import { SyncService } from "../../services/sync/services/SyncService";
import { COMMON_STORE_KEYS, LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { fetchCategories } from "../categories/categoriesSlice";
import { fetchListItems, fetchLists } from "../lists/listsSlice";
import { fetchPrompts } from "../prompts/promptsSlice";
import { RootState } from "../store";

export type ApplicationSliceType = {
  isOnline: boolean;
  installDialogToggle: boolean;
};

const initialState: ApplicationSliceType = {
  isOnline: true,
  installDialogToggle: false,
};

export const toggleIsOnline = createAsyncThunk<boolean, boolean>(
  "application/toggleIsOnline",
  async (isOnline) => isOnline,
);

export const enqueueSync = createAsyncThunk<void>("application/enqueueSync", async () => {
  try {
    SyncService.enqueue();
  } catch (e) {
    console.log(e);
  }
});

export const runSync = createAsyncThunk<void, number>(
  "application/runSync",
  async (interval, { dispatch, getState }) => {
    try {
      const checkSignIn = () => !!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL);
      const setIsOnline = (isOnline: boolean) => {
        dispatch(toggleIsOnline(isOnline));
      };
      const listsSyncer = new SyncListsService(new ChangesTracker(0), () => {
        dispatch(fetchLists());
        const state = getState() as RootState;
        if (state.lists.selectedList !== null) {
          dispatch(fetchListItems(state.lists.selectedList.localId));
        }
      });
      const promptsSyncer = new SyncPromptsService(new ChangesTracker(1), () => {
        dispatch(fetchPrompts());
      });
      const categoriesSyncer = new SyncCategoriesService(new ChangesTracker(2), () => {
        dispatch(fetchCategories());
      });
      SyncService.run(interval, checkSignIn, setIsOnline, [
        categoriesSyncer,
        listsSyncer,
        promptsSyncer,
      ]);
    } catch (e) {
      console.log(e);
    }
  },
);

export const stopSync = createAsyncThunk<void>("application/stopSync", async () => {
  try {
    SyncService.stop();
  } catch (e) {
    console.log(e);
  }
});

export const fetchInstallDialogToggle = createAsyncThunk<boolean | null, void>(
  "application/fetchInstallDialogToggle",
  async () => {
    try {
      return !!(await InternalStorageCommonService.getValue(
        COMMON_STORE_KEYS.INSTALL_DIALOG_TOGGLE,
      ));
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const setInstallDialogToggle = createAsyncThunk<boolean | null, boolean>(
  "application/setInstallDialogToggle",
  async (checked) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.INSTALL_DIALOG_TOGGLE,
        checked,
      );
      return checked;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const fetchInstallDialogLastShow = createAsyncThunk<number | null, void>(
  "application/fetchInstallDialogLastShow",
  async () => {
    try {
      return (
        ((await InternalStorageCommonService.getValue(
          COMMON_STORE_KEYS.INSTALL_DIALOG_LAST_SHOW,
        )) as number | undefined) ?? 0
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const setInstallDialogLastShow = createAsyncThunk<void, number>(
  "application/setInstallDialogLastShow",
  async (lastShowTimestamp) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.INSTALL_DIALOG_LAST_SHOW,
        lastShowTimestamp,
      );
    } catch (e) {
      console.log(e);
    }
  },
);

export const setShowRegistationLastShow = createAsyncThunk<void, number>(
  "application/setShowRegistationLastShow",
  async (lastShow) => {
    try {
      await InternalStorageCommonService.addOrUpdateValue(
        COMMON_STORE_KEYS.SHOW_REGISTRATION_LAST_SHOW,
        lastShow,
      );
    } catch (e) {
      console.log(e);
    }
  },
);
export const fetchShowRegistrationLastShow = createAsyncThunk<number | null, void>(
  "application/fetchShowRegistrationLastShow",
  async () => {
    try {
      const lastShow =
        ((await InternalStorageCommonService.getValue(
          COMMON_STORE_KEYS.SHOW_REGISTRATION_LAST_SHOW,
        )) as number | undefined) ?? 0;
      return lastShow;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toggleIsOnline.fulfilled, (state, action) => {
      state.isOnline = action.payload;
    });
    builder.addCase(fetchInstallDialogToggle.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.installDialogToggle = action.payload;
      }
    });
    builder.addCase(setInstallDialogToggle.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.installDialogToggle = action.payload;
      }
    });
  },
});
