export const CheckboxSquareDisabled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M2 5.61111C2 4.65338 2.38046 3.73488 3.05767 3.05767C3.73488 2.38046 4.65338 2 5.61111 2H18.3889C19.3466 2 20.2651 2.38046 20.9423 3.05767C21.6195 3.73488 22 4.65338 22 5.61111V18.3889C22 19.3466 21.6195 20.2651 20.9423 20.9423C20.2651 21.6195 19.3466 22 18.3889 22H5.61111C4.65338 22 3.73488 21.6195 3.05767 20.9423C2.38046 20.2651 2 19.3466 2 18.3889V5.61111Z"
        fill="#EBF1F5"
      />
      <path
        d="M2 5.61111C2 4.65338 2.38046 3.73488 3.05767 3.05767C3.73488 2.38046 4.65338 2 5.61111 2H18.3889C19.3466 2 20.2651 2.38046 20.9423 3.05767C21.6195 3.73488 22 4.65338 22 5.61111V18.3889C22 19.3466 21.6195 20.2651 20.9423 20.9423C20.2651 21.6195 19.3466 22 18.3889 22H5.61111C4.65338 22 3.73488 21.6195 3.05767 20.9423C2.38046 20.2651 2 19.3466 2 18.3889V5.61111ZM5.61111 3.66667C5.09541 3.66667 4.60084 3.87153 4.23618 4.23618C3.87153 4.60084 3.66667 5.09541 3.66667 5.61111V18.3889C3.66667 19.4622 4.53778 20.3333 5.61111 20.3333H18.3889C18.9046 20.3333 19.3992 20.1285 19.7638 19.7638C20.1285 19.3992 20.3333 18.9046 20.3333 18.3889V5.61111C20.3333 5.09541 20.1285 4.60084 19.7638 4.23618C19.3992 3.87153 18.9046 3.66667 18.3889 3.66667H5.61111Z"
        fill="#C1CED6"
      />
    </svg>
  );
};
