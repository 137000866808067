/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddItemPromptRequest } from '../models/AddItemPromptRequest';
import type { ItemPromptDto } from '../models/ItemPromptDto';
import type { UpdateItemPromptRequest } from '../models/UpdateItemPromptRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserItemPromptsService {

    /**
     * @returns ItemPromptDto Success
     * @throws ApiError
     */
    public static getApiUserItemPrompts(): CancelablePromise<Array<ItemPromptDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/item-prompts',
        });
    }

    /**
     * @param requestBody 
     * @returns ItemPromptDto Success
     * @throws ApiError
     */
    public static postApiUserItemPrompts(
requestBody?: AddItemPromptRequest,
): CancelablePromise<ItemPromptDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/item-prompts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ItemPromptDto Success
     * @throws ApiError
     */
    public static putApiUserItemPrompts(
requestBody?: UpdateItemPromptRequest,
): CancelablePromise<ItemPromptDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/item-prompts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
