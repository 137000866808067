export const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.9578 19.5814C20.887 17.8363 22 15.2726 22 12.2274C22 11.5181 21.935 10.8367 21.8149 10.1819H12.2041V14.0544H17.6953C17.4547 15.3 16.7309 16.3544 15.6455 17.0637M18.9578 19.5814C17.2691 21.1093 14.9592 22 12.2041 22C8.21547 22 4.77361 19.7544 3.09445 16.4912M18.9578 19.5814L15.6455 17.0637M15.6455 17.0637C14.7366 17.6637 13.5771 18.0274 12.2041 18.0274C9.55102 18.0274 7.29663 16.2726 6.4898 13.9093M3.09445 7.51814C2.39915 8.86372 2 10.3819 2 12C2 13.6181 2.39867 15.1363 3.09445 16.4819L5.73802 14.4633L6.4898 13.9C6.28571 13.3 6.16516 12.6637 6.16516 12C6.16516 11.3363 6.28571 10.7 6.4898 10.1M3.09445 7.51814C4.77361 4.24558 8.215 2 12.2041 2C14.9592 2 17.2596 2.9907 19.0313 4.6093L16.1096 7.47256C15.0422 6.4907 13.7067 5.9814 12.2041 5.9814C9.55102 5.9814 7.29663 7.73628 6.4898 10.1M3.09445 7.51814L6.4898 10.1"
        stroke="#F8FAFC"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
