import { Box, IconButton, Typography } from "@mui/material";
import "./LoginTitle.scss";
import { NavLink } from "react-router-dom";

import { ArrowBack } from "../../assets/Icons/ArrowBack";

export interface LoginTitleProps {
  text: string;
  backPath?: string;
}

export const LoginTitle = (props: LoginTitleProps) => {
  return (
    <Box className="login-title-container">
      {props.backPath && (
        <NavLink to={props.backPath} className={"login-title-icon"}>
          <IconButton>
            <ArrowBack />
          </IconButton>
        </NavLink>
      )}
      <Typography>{props.text}</Typography>
    </Box>
  );
};
