import "./AddIcon.scss";

export const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      className="add-icon"
    >
      <path
        d="M7.16667 8.83333V11.3333C7.16667 11.5694 7.24653 11.7674 7.40625 11.9271C7.56597 12.0868 7.76389 12.1667 8 12.1667C8.23611 12.1667 8.43403 12.0868 8.59375 11.9271C8.75347 11.7674 8.83333 11.5694 8.83333 11.3333V8.83333H11.3333C11.5694 8.83333 11.7674 8.75347 11.9271 8.59375C12.0868 8.43403 12.1667 8.23611 12.1667 8C12.1667 7.76389 12.0868 7.56597 11.9271 7.40625C11.7674 7.24653 11.5694 7.16667 11.3333 7.16667H8.83333V4.66667C8.83333 4.43056 8.75347 4.23264 8.59375 4.07292C8.43403 3.91319 8.23611 3.83333 8 3.83333C7.76389 3.83333 7.56597 3.91319 7.40625 4.07292C7.24653 4.23264 7.16667 4.43056 7.16667 4.66667V7.16667H4.66667C4.43056 7.16667 4.23264 7.24653 4.07292 7.40625C3.91319 7.56597 3.83333 7.76389 3.83333 8C3.83333 8.23611 3.91319 8.43403 4.07292 8.59375C4.23264 8.75347 4.43056 8.83333 4.66667 8.83333H7.16667ZM2.16667 15.5C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663194 1.31597 0.989583 0.989583C1.31597 0.663194 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.684 0.663194 15.0104 0.989583C15.3368 1.31597 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3368 14.684 15.0104 15.0104C14.684 15.3368 14.2917 15.5 13.8333 15.5H2.16667ZM2.16667 13.8333H13.8333V2.16667H2.16667V13.8333Z"
        fill="#C1CED6"
      />
    </svg>
  );
};
