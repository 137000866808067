export const EyeOpened = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99998 10.6667C8.83331 10.6667 9.54164 10.375 10.125 9.79169C10.7083 9.20835 11 8.50002 11 7.66669C11 6.83335 10.7083 6.12502 10.125 5.54169C9.54164 4.95835 8.83331 4.66669 7.99998 4.66669C7.16664 4.66669 6.45831 4.95835 5.87498 5.54169C5.29164 6.12502 4.99998 6.83335 4.99998 7.66669C4.99998 8.50002 5.29164 9.20835 5.87498 9.79169C6.45831 10.375 7.16664 10.6667 7.99998 10.6667ZM7.99998 9.46669C7.49998 9.46669 7.07498 9.29169 6.72498 8.94169C6.37498 8.59169 6.19998 8.16669 6.19998 7.66669C6.19998 7.16669 6.37498 6.74169 6.72498 6.39169C7.07498 6.04169 7.49998 5.86669 7.99998 5.86669C8.49998 5.86669 8.92498 6.04169 9.27498 6.39169C9.62498 6.74169 9.79998 7.16669 9.79998 7.66669C9.79998 8.16669 9.62498 8.59169 9.27498 8.94169C8.92498 9.29169 8.49998 9.46669 7.99998 9.46669ZM7.99998 12.6667C6.51109 12.6667 5.15275 12.2667 3.92498 11.4667C2.6972 10.6667 1.72775 9.61113 1.01664 8.30002C0.961087 8.20002 0.91942 8.09724 0.891642 7.99169C0.863864 7.88613 0.849976 7.7778 0.849976 7.66669C0.849976 7.55558 0.863864 7.44724 0.891642 7.34169C0.91942 7.23613 0.961087 7.13335 1.01664 7.03335C1.72775 5.72224 2.6972 4.66669 3.92498 3.86669C5.15275 3.06669 6.51109 2.66669 7.99998 2.66669C9.48886 2.66669 10.8472 3.06669 12.075 3.86669C13.3028 4.66669 14.2722 5.72224 14.9833 7.03335C15.0389 7.13335 15.0805 7.23613 15.1083 7.34169C15.1361 7.44724 15.15 7.55558 15.15 7.66669C15.15 7.7778 15.1361 7.88613 15.1083 7.99169C15.0805 8.09724 15.0389 8.20002 14.9833 8.30002C14.2722 9.61113 13.3028 10.6667 12.075 11.4667C10.8472 12.2667 9.48886 12.6667 7.99998 12.6667ZM7.99998 11.3334C9.25553 11.3334 10.4083 11.0028 11.4583 10.3417C12.5083 9.68058 13.3111 8.78891 13.8666 7.66669C13.3111 6.54447 12.5083 5.6528 11.4583 4.99169C10.4083 4.33058 9.25553 4.00002 7.99998 4.00002C6.74442 4.00002 5.59164 4.33058 4.54164 4.99169C3.49164 5.6528 2.68886 6.54447 2.13331 7.66669C2.68886 8.78891 3.49164 9.68058 4.54164 10.3417C5.59164 11.0028 6.74442 11.3334 7.99998 11.3334Z"
        fill="#8399A8"
      />
    </svg>
  );
};
