import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { PaletteMode } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";

type AppColors = {
  BG: string;
  PS700: string;
  Neutral100: string;
  Neutral200: string;
  Neutral300: string;
  Neutral400: string;
  Neutral700: string;
  Neutral900: string;
  SW600: string;
};

type ColorThemes = { light: AppColors; dark: AppColors };

const colorThemes: ColorThemes = {
  light: {
    BG: "#FFF",
    PS700: "#08875D",
    Neutral100: "#F8FAFC",
    Neutral200: "#EBF1F5",
    Neutral300: "#E1E9EF",
    Neutral400: "#C1CED6",
    Neutral700: "#313B42",
    Neutral900: "#1B2227",
    SW600: "#DB7712",
  },
  dark: {
    BG: "#FFF",
    PS700: "#08875D",
    Neutral100: "#F8FAFC",
    Neutral200: "#EBF1F5",
    Neutral300: "#E1E9EF",
    Neutral400: "#C1CED6",
    Neutral700: "#313B42",
    Neutral900: "#1B2227",
    SW600: "#DB7712",
  },
};

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  const colors: AppColors = colorThemes[mode];
  return {
    typography: {
      fontFamily: [
        "Inter",
        "-apple-system",
        "BlinkMacSystemFont",
        "Roboto",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    palette: {
      mode,
      background: {
        default: colors.BG,
        paper: colors.BG,
      },
      text: {
        primary: colors.Neutral900,
      },
      primary: {
        main: colors.PS700,
        contrastText: colors.Neutral100,
      },
      secondary: {
        main: colors.Neutral900,
        contrastText: colors.Neutral100,
      },
      warning: {
        main: colors.SW600,
      },
      info: {
        main: colors.Neutral400,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            textTransform: "none",
            width: "max-content",
            padding: "8px 16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            "& .MuiButton-startIcon": {
              marginRight: "12px",
              marginLeft: 0,
              "& .MuiSvgIcon-root": {
                fontSize: "24px",
              },
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: colors.Neutral200,
            height: "1px",
            margin: "12px 0px 12px 16px",
            opacity: ".5",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          outlined: {
            width: "190px",
            height: "40px",
            boxSizing: "border-box",
            padding: "8px 10px",
            borderRadius: "8px",
            letterSpacing: "0.25px",
            background: colors.BG,
            border: `1px solid ${colors.Neutral300}`,
            "& ~.MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        },
        defaultProps: {
          IconComponent: (props) => (
            <KeyboardArrowUpOutlinedIcon {...props} sx={{ fontSize: 15 }} />
          ),
          MenuProps: {
            sx: {
              "& .MuiMenuItem-root": {
                minHeight: "46px",
                paddingTop: "10px",
                paddingBottom: "10px",
                "& .MuiTypography-root ": {
                  fontSize: "17px",
                  lineHeight: "24px",
                  letterSpacing: "0.3px",
                },
                "& .MuiChip-root ": {
                  backgroundColor: colors.Neutral100,
                  color: colors.Neutral700,
                },
                "&.Mui-selected": {
                  color: colors.PS700,
                  backgroundColor: colors.BG,
                  "&.Mui-focusVisible": {
                    backgroundColor: colors.BG,
                  },
                },
                "&::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: "16px",
                  right: "0",
                  borderBottom: `1px solid ${colors.Neutral400}`,
                  opacity: ".5",
                },
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: "7px",
            "&.Mui-disabled .MuiSvgIcon-root": {
              color: colors.Neutral400,
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "28px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          filled: {
            padding: "4px 8px",
            borderRadius: "4px",
            height: "24px",
            boxSizing: "border-box",
            "& .MuiChip-avatar": {
              width: "15px",
              height: "15px",
              color: colors.Neutral100,
              backgroundColor: colors.Neutral400,
              margin: 0,
              padding: 0,
            },
            "& .MuiChip-icon": {
              width: "15px",
              height: "15px",
              margin: 0,
              padding: 0,
            },
            "& .MuiChip-label": {
              fontSize: "11px",
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0.2px",
              paddingLeft: "4px",
              paddingRight: 0,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
            "& .MuiAccordionSummary-content": {
              margin: 0,
              alignItems: "center",
            },
            "& .MuiAccordionDetails-root": {
              padding: "0",
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
    },
  };
};
