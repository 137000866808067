import config from "../appConfig/config";

import AuthService from "./auth/AuthService";
import { OpenAPI } from "./openapi";

export const initServices = () => {
  OpenAPI.BASE = config.ApiUrl;
  OpenAPI.TOKEN = async () => {
    return (await AuthService.getAccessToken()) || "";
  };

  if (process.env.NODE_ENV !== "development" && !config.Debug) {
    console.log = () => null;
    console.time = () => null;
    console.timeEnd = () => null;
  }
};
