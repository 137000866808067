import { Box, TextField, Typography } from "@mui/material";
import React, { ReactNode, useEffect, useRef } from "react";
import "./BaseInput.scss";

interface BaseInputProps {
  className?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  onEnter?: () => void;
  autoFocus?: boolean;
}

export const BaseInput = (props: BaseInputProps) => {
  return (
    <Box>
      <TextField
        variant="outlined"
        placeholder={props.placeholder}
        className={`base-input ${props.className}`}
        InputProps={{
          autoComplete: "off",
          startAdornment: props.startAdornment,
          endAdornment: props.endAdornment,
        }}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        error={!!props.errorMessage}
        onKeyDown={(e) => {
          if (e.key === "Enter" && props.onEnter) {
            props.onEnter();
          }
        }}
        autoFocus={props.autoFocus}
      />
      {props.errorMessage && (
        <Typography className="base-input-error" color="error">
          {props.errorMessage}
        </Typography>
      )}
    </Box>
  );
};
