import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";

import DoorOpen from "../../assets/img/door-open.svg";
import { LoginInput } from "../LoginInput/LoginInput";
import "./LoginCheck.scss";

interface LoginCheckProps {
  onClose: () => void;
  onContinue: () => void;
}
export const LoginCheck = (props: LoginCheckProps) => {
  const [login, setLogin] = useState("");
  return (
    <Stack className="login-check-container">
      <Typography className="login-check-title">
        Войти без пароля
        <IconButton className="login-check-close" onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </Typography>
      <img src={DoorOpen} alt="door-open" />
      <Typography className="login-check-text">
        Попробуйте войти по ссылке. Введите email адрес, связанный с вашим аккаунтом,
        чтобы мы могли отправить вам ссылку
      </Typography>
      <LoginInput
        value={login}
        icon={<CancelOutlinedIcon />}
        onChange={(val) => setLogin(val)}
        name="Ваш адрес электронной почты"
        onIconClick={() => {
          setLogin("");
        }}
        type="text"
      />

      <Button className="login-check-continue" onClick={() => props.onContinue()}>
        Отправить ссылку для входа
      </Button>
    </Stack>
  );
};
