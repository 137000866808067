import { Box, Checkbox, Typography } from "@mui/material";
import { ReactNode } from "react";

import "./CheckboxField.scss";
import { CheckboxSquare } from "../../assets/Icons/CheckboxSquare";
import { CheckboxSquareChecked } from "../../assets/Icons/CheckboxSquareChecked";
import { CheckboxSquareDisabled } from "../../assets/Icons/CheckboxSquareDisabled";

interface CheckboxFieldProps {
  checked: boolean;
  onClick?: () => void;
  children?: ReactNode;
  disabled?: boolean;
}

export const CheckboxField = (props: CheckboxFieldProps) => {
  const handleClick = () => {
    if (!props.disabled && props.onClick) {
      props.onClick();
    }
  };

  return (
    <Box
      className={`checkbox-field ${props.disabled && "checkbox-field--disabled"}`}
      onClick={handleClick}
    >
      <Checkbox
        className="checkbox-field-check"
        checked={props.checked}
        size="small"
        icon={props.disabled ? <CheckboxSquareDisabled /> : <CheckboxSquare />}
        checkedIcon={<CheckboxSquareChecked />}
      />
      <Typography className="checkbox-field-text">{props.children}</Typography>
    </Box>
  );
};
