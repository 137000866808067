import "./ColorPicker .scss";
import { Box, List, ListItem } from "@mui/material";

import checkIcon from "../../assets/Icons/check-icon.svg";

export interface ColorPickerProps {
  colors: string[];
  selectedColor: string;
  onColorSelect: (color: string) => void;
}

export const ColorPicker = (props: ColorPickerProps) => {
  return (
    <List className="color-picker">
      {props.colors.map((color) => (
        <ListItem
          key={color}
          className="color-button"
          onClick={() => props.onColorSelect(color)}
          sx={{ backgroundColor: color }}
        >
          {props.selectedColor === color && <Box component="img" src={checkIcon} />}
        </ListItem>
      ))}
    </List>
  );
};
