import { Avatar, Box, CircularProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import EditIcon from "../../assets/Icons/edit.svg";
import { selectApplicationState } from "../../store/application/selectors";
import { updateProfile, uploadProfilePicture } from "../../store/auth/authSlice";
import { selectAuthState } from "../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Alert } from "../Alert/Alert";
import { CurtainPopup } from "../CurtainPopup/CurtainPopup";
import { CurtainPopupHeading } from "../CurtainPopupHeading/CurtainPopupHeading";
import { ImageInput } from "../ImageInput/ImageInput";
import { LoginInput } from "../LoginInput/LoginInput";
import "./SettingProfile.scss";

export type SettingProfileProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SettingProfile = (props: SettingProfileProps) => {
  const { user } = useAppSelector(selectAuthState);
  const { isOnline } = useAppSelector(selectApplicationState);
  const dispatch = useAppDispatch();

  const [name, setName] = useState<string>("");
  const [img, setImg] = useState<string>("");
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [isProfileUpdating, setIsProfileUpdating] = useState<boolean>(false);
  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string>("");

  useEffect(() => {
    setName(user?.name ?? "");
    setImg(user?.profileUrl ?? "");
    setIsImageUploading(false);
    setIsProfileUpdating(false);
    setNameError("");
  }, [props.isOpen, user]);

  useEffect(() => {
    const wereChanges = name !== user?.name || img !== user?.profileUrl;
    setIsFormInvalid(!wereChanges || !!nameError || isProfileUpdating);
  }, [name, img, user, nameError, isProfileUpdating]);

  const handleImageUpload = async (image: File) => {
    setIsImageUploading(true);
    const url = await dispatch(uploadProfilePicture(image));
    setIsImageUploading(false);
    setImg(url ?? "");
  };

  const handleNameChange = (name: string) => {
    setName(name);
    if (name.length > 200) {
      setNameError("Максимальная длина имени 200 символов");
    } else if (name.length === 0) {
      setNameError("Обязательно для заполнения");
    } else {
      setNameError("");
    }
  };

  const handleComplete = async () => {
    if (isFormInvalid) {
      return;
    }
    setIsProfileUpdating(true);
    await dispatch(updateProfile({ name: name, profileUrl: img }));
    setIsProfileUpdating(false);
    props.onClose();
  };

  return (
    <CurtainPopup open={props.isOpen} onClose={props.onClose}>
      <CurtainPopupHeading
        title="Мои Данные"
        onConfirm={handleComplete}
        isConfirmDisabled={isFormInvalid}
        onCancel={props.onClose}
      />
      <Stack className="profile">
        <Box className="profile-image">
          {isImageUploading ? (
            <CircularProgress size={60} />
          ) : (
            <>
              <Avatar className="profile-image-avatar" src={img || ""} />
              <ImageInput
                id="profilePictureInput"
                className="profile-image-input"
                onChange={handleImageUpload}
              />
              {isOnline && (
                <label htmlFor="profilePictureInput">
                  <img src={EditIcon} alt="edit" className="profile-image-edit" />
                </label>
              )}
            </>
          )}
        </Box>
        <LoginInput
          name="Ваше имя"
          type="text"
          value={name}
          onChange={handleNameChange}
          errorText={nameError}
          isError={!!nameError}
          disabled={!isOnline}
        />
        <LoginInput
          name="Почта"
          type="text"
          value={user?.email ?? ""}
          onChange={(e) => e}
          disabled={true}
        />
        {!isOnline && (
          <Alert type="warning">
            В оффлайн режиме изменения настроек недоступно. Подключитесь к сети Интернет
          </Alert>
        )}
      </Stack>
    </CurtainPopup>
  );
};
