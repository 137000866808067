import { Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

import "./LoginQuestion.scss";

interface LoginQuestionProps {
  question: string;
  linkText: string;
  linkAddress: string;
}

export const LoginQuestion = (props: LoginQuestionProps) => (
  <Stack>
    <Typography className="login-question">
      {props.question}{" "}
      <NavLink to={props.linkAddress} className={"login-question-link"}>
        {props.linkText}
      </NavLink>
    </Typography>
  </Stack>
);
