import { Box, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";

import copiedIcon from "../../assets/Icons/copied.svg";
import copyIcon from "../../assets/Icons/copy.svg";

import "./CopyField.scss";

export interface CopyFieldProps {
  value: string;
  onCopy?: () => void;
}

export const CopyField = (props: CopyFieldProps) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertTimeout, setAlertTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    await navigator?.clipboard?.writeText(props?.value);
    setIsCopied(true);
    if (alertTimeout) {
      clearTimeout(alertTimeout);
    }
    setShowAlert(true);
    setAlertTimeout(
      setTimeout(() => {
        setShowAlert(false);
      }, 2000),
    );
    if (props.onCopy) {
      props.onCopy();
    }
  };

  return (
    <TextField
      className="copy-field"
      value={props.value}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <IconButton size="small" onClick={handleCopy}>
            <Box component="img" src={isCopied ? copiedIcon : copyIcon} />
            <Box
              className={`copy-field-alert ${showAlert && "copy-field-alert--active"}`}
            >
              Скопировано!
            </Box>
          </IconButton>
        ),
      }}
    />
  );
};
