import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import "./LoginOptions.scss";

import { EmailIcon } from "../../assets/Icons/EmailIcon";
import { GoogleIcon } from "../../assets/Icons/GoogleIcon";

export interface LoginOptionInterface {
  onLoginGoogle: () => void;
  onLoginEmail: () => void;
}
export const LoginOptions = (props: LoginOptionInterface) => {
  return (
    <Stack className="login-options">
      <Button
        startIcon={<GoogleIcon />}
        className="login-options-button-google"
        onClick={() => props.onLoginGoogle()}
        variant="contained"
        color="primary"
      >
        <Typography>Продолжить с Google</Typography>
      </Button>
      <Box className="login-options-divider-container">
        <Divider />
        <Typography className="login-options-divider">ИЛИ</Typography>
        <Divider />
      </Box>
      <Button
        startIcon={<EmailIcon />}
        className="login-options-button-email"
        onClick={props.onLoginEmail}
      >
        <Typography>Продолжить с Email</Typography>
      </Button>
    </Stack>
  );
};
