import "./PageLoading.scss";
import { Box, CircularProgress } from "@mui/material";

export const PageLoading = () => {
  return (
    <Box className="page-loading">
      <CircularProgress size={70} />
    </Box>
  );
};
