import { ListItemCategoryInternalModel } from "../../internalStorage/models/ListItemCategoryInternalModel";
import { ListItemPromptInternalModel } from "../../internalStorage/models/ListItemPromptInternalModel";
import { InternalStorageCategoriesService } from "../../internalStorage/services/InternalStorageCategoriesService";
import { InternalStoragePromptsService } from "../../internalStorage/services/InternalStoragePromptsService";
import { ItemPromptDto, ItemPromptsService, UserItemPromptsService } from "../../openapi";
import { Syncer } from "../core/Syncer";

export class SyncPromptsService extends Syncer {
  private async saveServerPromptsToLocal(
    serverPrompts: ItemPromptDto[],
    localPromptsNames: Set<string>,
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStoragePromptsService.addOrUpdatePrompts(
      serverPrompts
        .map((serverPrompt: ItemPromptDto): ListItemPromptInternalModel | undefined => {
          const isOnLocal: boolean = localPromptsNames.has(serverPrompt.name);
          const localCategory: ListItemCategoryInternalModel | undefined =
            localCategories.find(
              (localCategory: ListItemCategoryInternalModel): boolean =>
                localCategory.id === serverPrompt.categoryId,
            );
          if (!isOnLocal && localCategory) {
            return {
              localId: serverPrompt.name,
              name: serverPrompt.name,
              order: serverPrompt.order,
              localCategoryId: localCategory.localId,
              created: serverPrompt.created,
              updated: serverPrompt.updated,
            };
          }
        })
        .filter(
          (
            prompt: ListItemPromptInternalModel | undefined,
          ): prompt is ListItemPromptInternalModel => prompt !== undefined,
        ),
    );
  }

  private async postLocalPromptsToServer(
    serverPromptsNames: Set<string>,
    localPrompts: ListItemPromptInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localPrompts.map(
        async (localPrompt: ListItemPromptInternalModel): Promise<void> => {
          const isOnServer: boolean = serverPromptsNames.has(localPrompt.name);
          const localCategory: ListItemCategoryInternalModel | undefined =
            localCategories.find(
              (localCategory: ListItemCategoryInternalModel): boolean =>
                localCategory.localId === localPrompt.localCategoryId,
            );

          if (!isOnServer && localCategory?.id) {
            await UserItemPromptsService.postApiUserItemPrompts({
              name: localPrompt.name,
              order: localPrompt.order,
              categoryId: localCategory.id,
            });
          }
        },
      ),
    );
  }

  private async updateLocalPrompts(
    serverPrompts: ItemPromptDto[],
    localPrompts: ListItemPromptInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStoragePromptsService.addOrUpdatePrompts(
      localPrompts
        .map(
          (
            localPrompt: ListItemPromptInternalModel,
          ): ListItemPromptInternalModel | undefined => {
            const serverPrompt: ItemPromptDto | undefined = serverPrompts.find(
              (serverPrompt: ItemPromptDto): boolean =>
                serverPrompt.name === localPrompt.name,
            );
            if (!serverPrompt?.updated || !localPrompt.updated) {
              return;
            }
            const localCategory: ListItemCategoryInternalModel | undefined =
              localCategories.find(
                (localCategory: ListItemCategoryInternalModel): boolean =>
                  localCategory.id === serverPrompt.categoryId,
              );
            const serverPromptUpdated: number = new Date(serverPrompt.updated).valueOf();
            const localPromptUpdated: number = new Date(localPrompt.updated).valueOf();
            const isServerPromptNewer: boolean =
              serverPromptUpdated - localPromptUpdated > 0;

            if (isServerPromptNewer && localCategory) {
              return {
                localId: localPrompt.localId,
                name: serverPrompt.name,
                order: serverPrompt.order,
                localCategoryId: localCategory.localId,
                created: serverPrompt.created,
                updated: serverPrompt.updated,
              };
            }
          },
        )
        .filter(
          (
            prompt: ListItemPromptInternalModel | undefined,
          ): prompt is ListItemPromptInternalModel => prompt !== undefined,
        ),
    );
  }

  private async updateServerPrompts(
    serverPrompts: ItemPromptDto[],
    localPrompts: ListItemPromptInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverPrompts.map(async (serverPrompt: ItemPromptDto): Promise<void> => {
        const localPrompt: ListItemPromptInternalModel | undefined = localPrompts.find(
          (localPrompt: ListItemPromptInternalModel): boolean =>
            localPrompt.name === serverPrompt.name,
        );
        if (!localPrompt?.updated || !localPrompt.updated) {
          return;
        }
        const localCategory: ListItemCategoryInternalModel | undefined =
          localCategories.find(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.localId === localPrompt.localCategoryId,
          );
        const serverPromptUpdated: number = new Date(serverPrompt.updated).valueOf();
        const localPromptUpdated: number = new Date(localPrompt.updated).valueOf();
        const isLocalPromptNewer: boolean = localPromptUpdated - serverPromptUpdated > 0;

        if (isLocalPromptNewer && localCategory?.id) {
          if (serverPrompt.isCustom) {
            await UserItemPromptsService.putApiUserItemPrompts({
              name: localPrompt.name,
              order: localPrompt.order,
              categoryId: localCategory.id,
            });
          } else {
            await UserItemPromptsService.postApiUserItemPrompts({
              name: localPrompt.name,
              order: localPrompt.order,
              categoryId: localCategory.id,
            });
          }
        }
      }),
    );
  }

  public async sync(signedId: boolean, wereChanges: boolean): Promise<void> {
    if (!signedId) {
      const localPrompts: ListItemPromptInternalModel[] =
        await InternalStoragePromptsService.getPrompts();
      if (!localPrompts.length) {
        console.log("Prompts for anonymous fetching...");
        console.time("Prompts for anonymous fetched");
        const serverPrompts: ItemPromptDto[] =
          await ItemPromptsService.getApiItemPrompts();
        const localPromptsNames: Set<string> = new Set(
          localPrompts.map(
            (localPrompt: ListItemPromptInternalModel) => localPrompt.name,
          ),
        );
        const localCategories: ListItemCategoryInternalModel[] =
          await InternalStorageCategoriesService.getCategories();
        await this.saveServerPromptsToLocal(
          serverPrompts,
          localPromptsNames,
          localCategories,
        );
        console.timeEnd("Prompts for anonymous fetched");
      }
    } else if (wereChanges) {
      console.log("Prompts synchronization is running...");
      console.time("Prompts synced");
      const beforeSyncTime = new Date().toISOString();
      const serverPrompts: ItemPromptDto[] =
        await UserItemPromptsService.getApiUserItemPrompts();
      const serverPromptsNames: Set<string> = new Set(
        serverPrompts.map((serverPrompt: ItemPromptDto) => serverPrompt.name),
      );
      const localPrompts: ListItemPromptInternalModel[] =
        await InternalStoragePromptsService.getPrompts();
      const localPromptsNames: Set<string> = new Set(
        localPrompts.map((localPrompt: ListItemPromptInternalModel) => localPrompt.name),
      );
      const localCategories: ListItemCategoryInternalModel[] =
        await InternalStorageCategoriesService.getCategories();
      await this.saveServerPromptsToLocal(
        serverPrompts,
        localPromptsNames,
        localCategories,
      );
      await this.postLocalPromptsToServer(
        serverPromptsNames,
        localPrompts,
        localCategories,
      );
      await this.updateLocalPrompts(serverPrompts, localPrompts, localCategories);
      await this.updateServerPrompts(serverPrompts, localPrompts, localCategories);
      await this.changesTracker.setLastSync(beforeSyncTime);
      console.timeEnd("Prompts synced");
    }
    this.afterSyncCallback();
  }
}
