import "./CategoryCreation.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import { CATEGORIES_COLORS } from "../../utils/constants";
import { BaseInput } from "../BaseInput/BaseInput";
import { ColorPicker } from "../ColorPicker/ColorPicker ";
import { CurtainPopupHeading } from "../CurtainPopupHeading/CurtainPopupHeading";

export interface CategoryCreationProps {
  categories: ListItemCategoryInternalModel[];
  currentListItem?: ListItemInternalModel;
  onCategoryCreate: (name: string, color: string) => void;
  onBack: () => void;
}

export const CategoryCreation = (props: CategoryCreationProps) => {
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryColor, setNewCategoryColor] = useState(CATEGORIES_COLORS[0]);
  const [newCategoryError, setNewCategoryError] = useState("");
  const categoriesNamesSet = useMemo(
    () => new Set(props.categories.map((c) => c.name)),
    [props.categories],
  );

  const handleNewCategoryInputChange = (inputValue: string) => {
    if (categoriesNamesSet.has(inputValue)) {
      setNewCategoryError("Такая категория уже есть");
    } else {
      setNewCategoryError("");
    }
    setNewCategoryName(inputValue);
  };

  const handleCreateCategory = () => {
    if (newCategoryName && newCategoryColor && !newCategoryError) {
      props.onCategoryCreate(newCategoryName, newCategoryColor);
      props.onBack();
    }
  };

  return (
    <Box className="category-creation">
      <CurtainPopupHeading
        title="Создание категории"
        subtitle={props.currentListItem?.name}
        onBack={props.onBack}
        onConfirm={handleCreateCategory}
        isConfirmDisabled={!newCategoryName || !newCategoryColor || !!newCategoryError}
      />
      <BaseInput
        className="category-creation-input"
        value={newCategoryName}
        placeholder={"Введите название категории"}
        onChange={(e) => handleNewCategoryInputChange(e.target.value.slice(0, 200))}
        endAdornment={
          newCategoryName && (
            <InputAdornment
              position="end"
              onClick={() => handleNewCategoryInputChange("")}
            >
              <HighlightOffOutlinedIcon />
            </InputAdornment>
          )
        }
        autoFocus={true}
        onEnter={handleCreateCategory}
        errorMessage={newCategoryError}
      />
      <Accordion disableGutters defaultExpanded className="category-creation-accordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="category-creation-accordion-title">
            Цвет категории
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ColorPicker
            colors={CATEGORIES_COLORS}
            selectedColor={newCategoryColor}
            onColorSelect={setNewCategoryColor}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
