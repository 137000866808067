import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export interface DeleteDialogProps {
  open: boolean;
  name: string;
  onClose: () => void;
  onOk: () => void;
  type: string;
}

export const DeleteDialog = (props: DeleteDialogProps) => {
  const handleClose = () => {
    props.onClose();
  };
  const handleOk = () => {
    props.onOk();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Удалить {props.type}</DialogTitle>
      <DialogContent>
        <Box>
          Вы уверены, что хотите удалить {props.type} &ldquo;{props.name}&rdquo;?
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Нет</Button>
        <Button onClick={handleOk}>Да</Button>
      </DialogActions>
    </Dialog>
  );
};
