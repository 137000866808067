import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import "./ResetDialog.scss";

export interface ResetDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ResetDialog = (props: ResetDialogProps) => {
  return (
    <Dialog open={props.open} onClose={() => props.onClose()} className="reset-dialog">
      <DialogTitle className="reset-dialog-title">Восстановление данных</DialogTitle>
      <DialogContent className="reset-dialog-content">
        <Typography>
          Восстанавливает последние сохраненные данные из облачного хранилища
        </Typography>
      </DialogContent>
      <DialogActions className="reset-dialog-actions">
        <Button onClick={() => props.onClose()} className="reset-dialog-cancel">
          Отменить
        </Button>
        <Button
          className="reset-dialog-confirm"
          onClick={() => props.onConfirm()}
          variant="contained"
          color="primary"
        >
          Восстановить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
