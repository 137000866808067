import "./EmptyContent.scss";
import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

interface EmptyContentProps {
  image?: ReactNode;
  title?: string;
  subtitle?: ReactNode;
  button?: ReactNode;
  className?: string;
}

export const EmptyContent = (props: EmptyContentProps) => {
  return (
    <Box component="section" className={`empty-content ${props.className}`}>
      {props.image}
      {props.title && (
        <Typography className="empty-content-title" component="h2">
          {props.title}
        </Typography>
      )}
      {props.subtitle && (
        <Typography className="empty-content-subtitle">{props.subtitle}</Typography>
      )}
      {props.button}
    </Box>
  );
};
