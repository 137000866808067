import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

import "./Alert.scss";

import { Info } from "../../assets/Icons/Info";

interface AlertProps {
  type?: "success" | "info" | "warning" | "danger";
  children?: ReactNode;
}

export const Alert = (props: AlertProps) => {
  return (
    <Box className={`alert ${props.type && `alert--${props.type}`}`}>
      <Box className={`alert-icon ${props.type && `alert-icon--${props.type}`}`}>
        <Info />
      </Box>
      <Typography className="alert-text">{props.children}</Typography>
    </Box>
  );
};
